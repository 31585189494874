export const useRequestModalStore = defineStore('modalRequestOrder', {
  state: () => ({
    modalProduct: {},
    showRequestOrderModal: false,
    comboProducts: {},
  }),
  getters: {},
  actions: {
    SET_MODAL_PRODUCT(product) {
      this.modalProduct = product
    },
    SET_COMBO_PRODUCTS(combo) {
      this.comboProducts = combo
    },
    SET_SHOW_REQUEST_ORDER_MODAL(name) {
      this.showRequestOrderModal = name
    },
    clearModalProduct() {
      this.modalProduct = {}
    },
    setComboProducts(products) {
      this.comboProducts = products
    },
    setModalProduct({ commit, dispatch }, product) {
      this.clearModalProduct()
      useApi()
        .$get(`product/${product.id}`)
        .then((response) => {
          this.modalProduct = response
          console.log(response)
        })
    },
    setShowRequestOrderModal(name) {
      this.clearModalProduct()
      this.showRequestOrderModal = name
    },
  },
})
